export const ROUTE_NAV = Object.freeze({
  terms: '/terms-and-conditions/',
  policy: '/privacy-policy/',
  cookiePolicy: '/cookie-policy/',
  purchaseTerms: '/purchase-terms/',
  subscriptionTerms: '/subscription-terms/',
  emailConfirmed: '/auth/email-confirmed/',
  doNotSellMyPersonalInfo: '/do-not-sell-my-personal-info/',
  doubleConnection: '/double-connection/',
  banned: '/banned/',

  authWelcome: '/auth/welcome/',
  authLoginEmail: '/auth/login-email/',
  authForgotPassword: '/auth/forgot-password/',
  authRegistrationEmail: '/auth/registration-email/',
  authRegistrationName: '/auth/registration-name/',
  authRegistrationGender: '/auth/registration-gender/',
  authCreateNewPassword: '/auth/create-new-password/',

  cabMain: '/cab/video-chat/',
  cabInvoiceHistory: '/cab/invoice-history/',
  cabBlocklist: '/cab/blocklist/',
  cabPrivateCall: '/cab/private-call/',
  cabTopProfiles: '/cab/top-profiles/',
  cabStories: '/cab/stories/',
  cabProfile: '/cab/profile/',
  cabProfileSettings: '/cab/profile-settings/',
  cabChat: '/cab/chat/',
  
})
